import ECROnTerminal from "./ECROnTerminal";
import Environment from "./Environment";
import PaymentEngine from "./PaymentEngine";
import Segment from "./Segment";
import TechnologyType from "./TechnologyType";
import Terminal from "./Terminal";
import TooltipText from "./TooltipText";

const ProjectCatalogueSections = {
  TECHNOLOGY: {
    title: ["PROJECT_TECHNOLOGY_LABEL"],
    options: TechnologyType,
    registerName: "technology",
    type: "RADIO",
    required: true,
  },
  TERMINAL_ATTENDANCE: {
    title: ["ENVIRONMENT_LABEL"],
    options: Environment,
    registerName: "environment",
    type: "RADIO",
    tooltipInfo: TooltipText.ENVIRONMENT,
    required: true,
  },
  SEGMENT: {
    title: ["INTEGRATION_SEGMENT_LABEL"],
    options: Segment,
    registerName: "segments",
    type: "CHECK",
    required: true,
  },
  TERMINAL: {
    title: ["TERMINAL_LABEL"],
    options: Terminal,
    registerName: "terminals",
    type: "CHECK",
    required: true,
  },
  PAYMENTENGINETYPES: {
    title: ["PROJECT_PAYMENT_ENGINE_LABEL"],
    options: PaymentEngine,
    registerName: "paymentEngineTypes",
    type: "CHECK",
    tooltipInfo: TooltipText.PAYMENT_ENGINE,
    required: true,
  },
  INTEGRATIONTYPE: {
    title: ["PROJECT_INTEGRATION_TYPE_LABEL"],
    type: "INTEGRATIONTYPE",
    required: true,
  },
  ECR_ON_TERMINAL: {
    title: ["ECR_ON_CCV_TERMINAL_LABEL", "ECR_ON_TERMINAL_LABEL"],
    options: ECROnTerminal,
    registerName: "ecronTerminal",
    type: "RADIO",
    required: true,
  },
};

const SolutionSections = {
  TERMINAL: [
    ProjectCatalogueSections.INTEGRATIONTYPE,
    ProjectCatalogueSections.ECR_ON_TERMINAL,
    ProjectCatalogueSections.TERMINAL_ATTENDANCE,
    ProjectCatalogueSections.TERMINAL,
    ProjectCatalogueSections.PAYMENTENGINETYPES,
    ProjectCatalogueSections.SEGMENT,
    ProjectCatalogueSections.TECHNOLOGY,
  ],
};

export { ProjectCatalogueSections, SolutionSections };
