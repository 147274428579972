import {
  CcvButton,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function Link({ onClick, testId, color, isExternalLink, children }) {
  return isExternalLink ? (
    <CcvButton
      type="secondary"
      onClick={onClick}
      testId={testId?.toLowerCase()}
      size="small"
      text={
        <span className="button-with-icon">
          {children}
          <CcvMaterialIcon iconName="open_in_new"></CcvMaterialIcon>
        </span>
      }
    />
  ) : (
    <CcvText size="medium" color={color}>
      <span
        className="url_link"
        onClick={onClick}
        data-testid={testId?.toLowerCase()}
      >
        {children}
      </span>
    </CcvText>
  );
}

export default Link;
