import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import InputLabel from "./InputLabel";

function Textarea({
  translate,
  labelText,
  tooltip,
  required,
  maxLength,
  field,
  defaultInputData,
  placeholder,
  disabled,
  setInput,
  validationError,
  className,
  testId,
  shouldValidateOnInput,
  clearErrors,
  setValue,
}) {
  const [inputData, setInputData] = useState("");

  useEffect(() => {
    setInputData(defaultInputData);
  }, [defaultInputData]);

  function setTextareaInput(textareaInput) {
    setInputData(textareaInput);
    setInput && setInput(textareaInput);
    setValue &&
      setValue(field, textareaInput.trim(), {
        shouldValidate: shouldValidateOnInput,
      });
    clearErrors && clearErrors(field);
  }

  return (
    <>
      {labelText && (
        <InputLabel
          labelText={labelText}
          translate={translate}
          required={!disabled && required}
          tooltipInfo={!disabled && tooltip}
        />
      )}

      <textarea
        className={"textarea " + className}
        maxLength={maxLength && maxLength}
        data-testid={(testId ? testId : field) + "_textarea"}
        type="text"
        value={setInput ? defaultInputData : inputData}
        disabled={disabled}
        placeholder={placeholder}
        onInput={(e) => setTextareaInput(e.target.value)}
      />

      {!disabled && maxLength && (
        <CcvText
          color="medium-grey"
          size="small"
          testId="characters_remaining_text"
        >
          <span className="characters-left-bold">
            {inputData ? maxLength - inputData?.length : maxLength}&nbsp;
          </span>
          {translate("CHARACTERS_REMAINING")}
        </CcvText>
      )}
      {validationError}
    </>
  );
}

export default Textarea;
