import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { Table, TableCell, TableRow } from "@mui/material";
import React from "react";
import FormatService from "../../../services/FormatService";
import EditableSelectList from "./EditableSelectList";
import EditableSelect from "./EditableSelect";
import DownloadLink from "../../common/button/DownloadLink";
import Link from "../../common/button/Link";
import TextCopyDownload from "./TextCopyDownload";
import ScannerResultWithList from "./ScannerResultWithList";

function RowTable({
  translate,
  data,
  editActive,
  formControl,
  isBorderDisabled,
}) {
  function getFormatData(item) {
    if (item.data && !item.unavailableData) {
      switch (item.type) {
        case "date":
          return FormatService.getShortDateFormat(item.data);
        case "link":
          return (
            <Link
              onClick={item.function}
              isExternalLink={item.isExternalLink}
              testId={item.data}
            >
              {translate(item.data)}
            </Link>
          );
        case "download":
          return (
            <DownloadLink
              downloadFunction={item.function}
              translatedText={translate(item.data)}
              testId={item.data}
            />
          );
        case "editable_select":
          return (
            <EditableSelect
              selectInfo={item}
              translate={translate}
              formControl={formControl}
              isCustomInput={isCustomInput}
              editActive={editActive}
            />
          );
        case "editable_select_list":
          return (
            <EditableSelectList
              selectorList={item}
              translate={translate}
              isCustomInput={isCustomInput}
              formControl={formControl}
              editActive={editActive}
            />
          );
        case "text_copy_download":
          return <TextCopyDownload buttonInfo={item} translate={translate} />;
        case "scanner_result_with_list":
          return (
            <ScannerResultWithList
              translatedButtonText={translate("VIEW_ONLINE_LABEL")}
              testId={item.testId}
              url={item.url}
              resultList={item.list}
              maxEntries={item.maxEntries}
              maxEntriesInfoMessage={item.maxEntriesInfoMessage}
              translate={translate}
            >
              {item.element}
            </ScannerResultWithList>
          );
        case "element":
          return item.data;
        case "list":
          return item.data
            .map((option) => translate(item.options[option].description))
            .join(", ");
        case "downloadAndImage":
          return (
            <span className="downloadAndImage">
              <DownloadLink
                downloadFunction={item.function}
                translatedText={translate(item.data)}
                testId={item.data}
              />
              {item.image}
            </span>
          );
        case "unavailable":
          return (
            <CcvText testId={item?.data?.toLowerCase()} color="grey">
              {translate(item.data)}
            </CcvText>
          );
        default:
          return (
            <span data-testid={item?.data?.toLowerCase()}>
              {translate(item.data)}
            </span>
          );
      }
    }
    return (
      <CcvText testId="no_info_available" color="grey">
        {translate("RELEASE_INFO_TEXT_NO_INFO")}
      </CcvText>
    );
  }

  function isCustomInput(data) {
    if (data) {
      return data?.includes("_OTHER")
        ? data.split("_OTHER")[0] + " (" + translate("OPTION_OTHER") + ")"
        : translate(data);
    } else {
      return translate("NOT_FILLED_IN");
    }
  }

  return (
    <Table>
      {data.map(
        (item) =>
          item && (
            <>
              <TableRow
                className={
                  "row-style " + (isBorderDisabled && "border-disabled")
                }
              >
                <TableCell className="title">
                  <CcvText size="medium" color="dark-grey" textStyle="bold">
                    {translate(item.columnName)}
                    {item.columnRequired && " *"}
                  </CcvText>
                  <CcvText
                    size="small"
                    color={item.columnSubTextColor}
                    testId={item.columnSubText?.toLowerCase()}
                  >
                    {translate(item.columnSubText)}
                  </CcvText>
                </TableCell>
                <TableCell className="data">
                  <CcvText size="medium" color="dark-grey">
                    {getFormatData(item)}
                  </CcvText>
                </TableCell>
              </TableRow>
            </>
          )
      )}
    </Table>
  );
}

export default RowTable;
