import React, { Suspense } from "react";
import RouterNav from "./config/Router";
import { CcvStylesheet } from "@ccv-oc-myccv/ccv-react-components";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import LoadingBlock from "./components/Loading/LoadingBlock";

ReactDOM.render(
  <div>
    <CcvStylesheet />
    <React.Suspense
      fallback={
        <div className="fallback-spinner">
          <LoadingBlock />
        </div>
      }
    >
      <I18nextProvider i18n={i18n}>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={true}
          progressClassName="toast-progressbar"
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          id="Toastify"
        />
        <BrowserRouter>
          <RouterNav />
        </BrowserRouter>
      </I18nextProvider>
    </React.Suspense>
  </div>,
  document.getElementById("root")
);
