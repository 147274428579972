/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { AppContext } from "../../config/AppContext";
import "../../style/CreateForm.scss";
import "../../style/Button.scss";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLocation, useNavigate } from "react-router-dom";
import SpinnerService from "../../services/SpinnerService";
import "../../style/TabNavigator.scss";
import {
  CcvButton,
  CcvMaterialIcon,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import ReleaseFormComponent from "./navigatorComponents/ReleaseFormComponent";
import TabNavigator from "../../components/TabNavigator";
import { ReleaseService } from "../../services/ReleaseService";
import { CcvStoreInfoService } from "../../services/CcvStoreInfoService";
import RequirementTestCatalogue from "../../components/catalogue/RequirementTestCatalogue";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import FunctionalTestingFormComponent from "./navigatorComponents/FunctionalTestingFormComponent";
import { ProjectService } from "../../services/ProjectService";
import { Spinner } from "../../components/Loading/Spinner";
import FormatService from "../../services/FormatService";
import LoadingDialog from "../../components/dialogs/LoadingDialog";
import CcvStoreInfo from "./navigatorComponents/CcvStoreInfoComponent";
import { CreateReleaseYupValidation } from "../../yupValidation/CreateReleaseYupValidation";

function CreateReleaseComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  let ccvStoreService = new CcvStoreInfoService(authService);
  let projectService = new ProjectService(authService);
  const navigate = useNavigate();
  const location = useLocation();
  const [ccvStoreInfo, setCcvStoreInfo] = useState();
  const [projectInfo, setProjectInfo] = useState();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const { promiseInProgress } = usePromiseTracker({ area: "create-release" });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [promiseErrorText, setPromiseErrorText] = useState();
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    clearErrors,
    trigger,
    setValue,
    getValues,
    control,
    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(CreateReleaseYupValidation()),
    defaultValues: {
      filesToDelete: [],
    },
  });

  async function getCcvStoreInfo(projectId) {
    return trackPromise(
      SpinnerService.errorSpinner(
        ccvStoreService
          .getCcvStoreInfo(projectId)
          .then((response) => {
            setCcvStoreInfo(response.data);
          })
          .catch((err) => {
            if (err.response.status != 404) {
              throw err;
            }
          }),
        t
      ),
      "app-info-area"
    );
  }

  async function getProjectInfo(projectId) {
    return trackPromise(
      SpinnerService.errorSpinner(
        projectService.getProjectById(projectId).then((response) => {
          setProjectInfo(response.data);
        }),
        t
      ),
      "flow-description-area"
    );
  }

  useEffect(() => {
    setTitle(t(props.title) + " " + location.state.projectName);
    Promise.all([
      getProjectInfo(location.state.projectId),
      getCcvStoreInfo(location.state.projectId),
    ]);
  }, []);

  useEffect(() => {
    if (ccvStoreInfo) {
      setValue("applicationName", ccvStoreInfo.applicationName);
      setValue("description", ccvStoreInfo.description);
      setValue("shortDescription", ccvStoreInfo.shortDescription);
    }
  }, [ccvStoreInfo]);

  useEffect(() => {
    if (projectInfo) {
      setValue("flowDescription", projectInfo.flowDescription);
    }
  }, [projectInfo]);

  function onSubmit(data) {
    setPromiseErrorText();
    setIsDialogOpen(true);
    trackPromise(
      SpinnerService.successSpinner(
        releaseService
          .createRelease(getReleaseFormData(data))
          .then((response) => {
            localStorage.setItem("activeSection", "GENERAL_INFO");
            navigate("/release/detail/" + response.data.id);
          }),
        t,
        "TOAST_RELEASE_CREATED",
        setPromiseErrorText
      ),
      "create-release"
    );
  }

  function getReleaseFormData(data) {
    const formData = new FormData();
    getSharepointData(data);
    getCcvStoreFormData(data, formData);
    data.projectId = location.state.projectId;
    data.lastEditedBy = authService.getEmail();
    data.lastUpdated = BigInt(Date.now()).toString();
    data.releaseOwnerRequest = {
      name: data.ownerName,
      role: data.ownerRole,
      creatorId: authService.getUserId(),
    };
    data.requirementTestRequest = {
      log4jVersion: data.log4jVersion,
      paymentEngineVersions: getPaymentEngineVersions(data),
      mapiVersion: data.mapiVersion,
    };

    formData.append(
      "file",
      new File(
        [data.apk[0]],
        FormatService.replaceSpecialChar(data.apk[0].name),
        {
          type: data.apk[0].type,
        }
      )
    );
    formData.append(
      "releaseRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );

    return formData;
  }

  function getSharepointData(data) {
    data.bupaId = authService.getBupaId();
    data.bupaName = authService.getBupaName();
    data.integrationType = location.state.integrationType;
    data.projectName = location.state.projectName;
    return data;
  }

  function getPaymentEngineVersions(data) {
    return data.paymentEngineVersions.map((version) => {
      return { name: version.name, version: version.version };
    });
  }

  function getCcvStoreFormData(data, formData) {
    data.ccvStoreInfoRequest = {
      lastUpdateAppIcon: BigInt(Date.now()).toString(),
      lastUpdateScreenshots: BigInt(Date.now()).toString(),
      lastEditedBy: authService.getEmail(),
      screenshotsToDeleteByName: data.filesToDelete,
      shortDescription: data.shortDescription,
      description: data.description,
      applicationName: data.applicationName,
      id: ccvStoreInfo?.id,
    };

    setAppIconFormData(data, formData);
    if (touchedFields["screenshots"]) {
      setScreenshotsFormData(data, formData);
    }
  }

  function setAppIconFormData(data, formData) {
    touchedFields["appIcon"] &&
      formData.append(
        "appIcon",
        new File(
          [data.appIcon[0]],
          "appicon" +
            data.appIcon[0].name.substring(
              data.appIcon[0].name.lastIndexOf(".")
            ),
          {
            type: data.appIcon[0].type,
          }
        )
      );
  }

  function setScreenshotsFormData(data, formData) {
    let imageOrderIndex = ccvStoreInfo ? 5 : 0;
    for (const image of data.screenshots) {
      if (!image.deletionName) {
        formData.append(
          "screenshots",
          new File(
            [image],
            imageOrderIndex +
              "_screenshot" +
              image.name.substring(image.name.lastIndexOf(".")),
            {
              type: image.type,
            }
          )
        );
        imageOrderIndex++;
      }
    }
  }

  function setScreenshotsToDelete(fileName) {
    let deletionFiles = getValues("filesToDelete");
    return setValue("filesToDelete", [...deletionFiles, fileName], {
      shouldTouch: true,
    });
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={"center form"}
      data-testid="createReleaseForm"
    >
      <LoadingDialog
        translate={t}
        dialogTitle={"CREATE_RELEASE_LOADING_TITLE"}
        loadingText={"CREATE_RELEASE_LOADING_MESSAGE"}
        open={isDialogOpen}
        errorText={promiseErrorText}
        isPromiseInProgress={promiseInProgress}
        closeDialog={() => {
          setIsDialogOpen(false);
        }}
      />
      <div className="message-block progress-block message-block-left">
        <CcvMessage
          text={
            <span className="message-block-text">
              <CcvMaterialIcon iconName="info" size="medium" />
              {t("CREATE_RELEASE_MESSAGE")}
            </span>
          }
          type="alert"
          testId="create_release_message"
        />
      </div>
      <TabNavigator
        sections={{
          GENERAL_INFO: {
            title: "RELEASE_INFO_HEADING",
            block: (
              <ReleaseFormComponent
                clearErrors={clearErrors}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                integrationType={location.state.integrationType}
                validate={trigger}
                paymentEngineTypes={location.state.paymentEngineTypes}
              />
            ),
            activated: true,
            previousSection: null,
            nextSection: "FUNCTIONAL_TESTING",
            validationFields: [
              "version",
              "releaseNotes",
              "paymentEngineVersions",
              "mapiVersion",
              "apk",
              "ownerName",
              "ownerRole",
            ],
          },
          FUNCTIONAL_TESTING: {
            title: "FUNCTIONAL_TESTING_HEADING",
            block: (
              <Spinner
                area="functional-testing-area"
                dataIsFetched={projectInfo}
              >
                <FunctionalTestingFormComponent
                  flowDescription={projectInfo?.flowDescription}
                  setValue={setValue}
                  errors={errors}
                  clearError={clearErrors}
                />
              </Spinner>
            ),
            activated: false,
            previousSection: "GENERAL_INFO",
            nextSection: "REQUIREMENT_TEST",
            validationFields: ["flowDescription"],
          },
          REQUIREMENT_TEST: {
            title: "REQUIREMENT_TEST_INFO_HEADING",
            block: (
              <RequirementTestCatalogue
                register={register}
                defaultCompleted={false}
                release={null}
                errors={errors}
                setValue={setValue}
                clearError={clearErrors}
              />
            ),
            activated: false,
            previousSection: "FUNCTIONAL_TESTING",
            nextSection: "APPLICATION_INFO",
            validationFields: ["log4jVersion", "requirementTests"],
          },
          APPLICATION_INFO: {
            title: "APPLICATION_INFO_HEADING",
            block: (
              <Spinner area="app-info-area" dataIsFetched={ccvStoreInfo}>
                <CcvStoreInfo
                  control={control}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  defaultData={ccvStoreInfo}
                  setFilesToDelete={setScreenshotsToDelete}
                  multiColumnView={true}
                  clearError={clearErrors}
                />
              </Spinner>
            ),
            activated: false,
            previousSection: "REQUIREMENT_TEST",
            nextSection: null,
            validationFields: [
              "appIcon",
              "screenshots",
              "shortDescription",
              "description",
              "applicationName",
            ],
          },
        }}
        activeSection={"GENERAL_INFO"}
        validate={trigger}
        setIsLastTab={setIsSaveButtonDisabled}
        isLastTab={isSaveButtonDisabled}
        handleSubmitButton={
          <CcvButton
            type="primary"
            size="normal"
            text={t("RELEASE_BUTTON_ADD_RELEASE")}
            disabled={promiseInProgress}
            onClick={handleSubmit(onSubmit)}
            testId="save_release_button"
          />
        }
      />
    </form>
  );
}

export default CreateReleaseComponent;
