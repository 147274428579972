import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function ButtonWithIcons({
  iconNames,
  buttonText,
  iconFirst,
  textClassName,
  iconClassName,
  translate,
  buttonSize,
  testid,
  buttonColor,
}) {
  return (
    <span
      className={
        (iconFirst && "reversed") + " button-with-icon " + iconClassName
      }
      data-testid={testid ? testid : buttonText?.toLowerCase() + "_icon_button"}
    >
      <CcvText size="medium" color={buttonColor || "medium-grey"}>
        <span className={textClassName} onClick={iconNames[0]?.onClick}>
          {translate(buttonText)}
        </span>
      </CcvText>
      <span className="button_icons">
        {iconNames.map((icon) => (
          <span
            onClick={icon.onClick}
            data-testid={icon.iconName + "_icon_button"}
          >
            <CcvMaterialIcon
              iconName={icon.iconName}
              size={buttonSize ? buttonSize : "medium"}
              iconStyle="outline"
            />
          </span>
        ))}
      </span>
    </span>
  );
}

export default ButtonWithIcons;
