import React from "react";
import Selector from "../../form/Selector";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function EditableSelectList({
  selectorList,
  translate,
  isCustomInput,
  formControl,
  editActive,
}) {
  return selectorList.data?.map((selector) => {
    return editActive ? (
      <div className="form-section">
        {selectorList.data.length > 1 && (
          <CcvText size="medium" color="dark-grey">
            {selectorList.options[selector.key].description}
          </CcvText>
        )}
        <Selector
          testId={selector.key?.toLowerCase()}
          registerName={selector.value}
          options={selectorList.options[selector.key]?.versions}
          initialSelectedItem={{
            id: selector.value,
            text: selector.isOtherOptionSelected
              ? translate("OTHER")
              : isCustomInput(selector.value),
          }}
          setCustomKey={selector.key}
          setCustomSelectedResult={selectorList.setValue}
          setInputResult={(value) => {
            selectorList.setValue(
              selector.key,
              value.target.value
                ? value.target.value + "_OTHER"
                : "NOT_FILLED_IN",
              true
            );
          }}
          isOtherOptionSelected={selector.isOtherOptionSelected}
          maxLength={selector.maxLength}
          translate={translate}
          control={formControl}
        />
      </div>
    ) : (
      <CcvText size="medium" color="dark-grey">
        {selectorList.data.length > 1 &&
          selectorList.options[selector.key].description + ":"}{" "}
        <span className="no-wrap">{isCustomInput(selector.initialValue)}</span>
      </CcvText>
    );
  });
}

export default EditableSelectList;
