import Lottie from "lottie-react";
import React from "react";
import ccvLoadingLottie from "../../style/lottie/ccv_loading_lottie.json";

function LoadingBlock() {
  return (
    <div className="ccv-spinner" data-testid="spinner">
      <Lottie
        animationData={ccvLoadingLottie}
        loop={true}
        data-testid="ccv_spinner"
      />
    </div>
  );
}

export default LoadingBlock;
