import React from "react";
import Link from "../../common/button/Link";
import ScannerResult from "../../common/ScannerResult";
import InfoTextWithIcon from "../../common/InfoTextWithIcon";
import { ShowForRole } from "../../ShowForRole";

function ScannerResultWithList({
  translate,
  url,
  translatedButtonText,
  testId,
  resultList,
  maxEntries,
  maxEntriesInfoMessage,
  children,
}) {
  function navigateToUrl() {
    window.open(url, "_blank");
  }

  function getListOfScannerResults() {
    return resultList.map((resultItem) => (
      <ScannerResult
        status={resultItem.status}
        resultName={resultItem.name}
        resultDescription={resultItem.description}
      />
    ));
  }

  function getExceededMaxEntriesResult() {
    return (
      <div>
        <ScannerResult
          status={"FAILED"}
          resultName={resultList.length + " " + translate("ERRORS_FOUND_TEXT")}
        />
        {maxEntriesInfoMessage && (
          <ShowForRole permission="Employee">
            <InfoTextWithIcon
              translatedInfoText={maxEntriesInfoMessage}
              testId={"application_info_tooltip"}
            />
          </ShowForRole>
        )}
      </div>
    );
  }

  function getResultList() {
    if (maxEntries && resultList.length > maxEntries) {
      return getExceededMaxEntriesResult();
    }
    return getListOfScannerResults();
  }

  return (
    <div>
      <span className="label-with-button">
        {children}
        {url && (
          <Link
            isExternalLink={true}
            onClick={() => navigateToUrl()}
            testId={testId}
          >
            {translatedButtonText}
          </Link>
        )}
      </span>
      {resultList && getResultList()}
    </div>
  );
}

export default ScannerResultWithList;
