import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import Environment from "../../domain/Environment";
import PaymentEngine from "../../domain/PaymentEngine";
import Terminal from "../../domain/Terminal";
import ProjectSolution from "../../domain/ProjectSolution";
import IntegrationType from "../../domain/IntegrationType";
import TechnologyType from "../../domain/TechnologyType";
import Segment from "../../domain/Segment";

function ProjectInfoPopup({ project, translate }) {
  function renderListItem(title, value) {
    return (
      <div className="list-item-project-info">
        <CcvText testId={title} size="small" color="dark-grey" textStyle="bold">
          {title}
        </CcvText>
        <CcvText size="small" testId={value} color="dark-grey">
          {value}
        </CcvText>
      </div>
    );
  }

  return (
    <div className="project-info-block">
      <CcvText testId="project_name" color="dark-grey" textStyle="bold">
        {project.projectName}
      </CcvText>
      <div className="multi-side-block">
        <div className="right">
          {renderListItem(
            "CCV Terminal",
            project.solution === "TERMINAL" ? "Yes" : "No"
          )}
          {renderListItem(
            "Terminals are",
            Environment[project.environment]?.description
          )}
          {renderListItem(
            "Terminal Type('s)",
            project.terminals
              ?.map((terminalKey) => Terminal[terminalKey].description)
              .join(", ")
          )}
          {renderListItem(
            "Payment engine",
            project.paymentEngineTypes
              ?.map((engineKey) => PaymentEngine[engineKey].description)
              .join(", ")
          )}
        </div>
        <div className="left">
          {renderListItem(
            "Integration solutions",
            ProjectSolution[project.solution]?.description
          )}
          {renderListItem(
            "Integration type",
            IntegrationType[project.integrationType]?.description
          )}
          {renderListItem(
            "Integration technologies",
            TechnologyType[project.technology]?.description
          )}
          {renderListItem(
            "Segments",
            project.segments
              ?.map((segmentKey) => translate(Segment[segmentKey].description))
              .join(", ")
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectInfoPopup;
