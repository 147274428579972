import { CcvButton } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../style/TabNavigator.scss";
import { ScrollToPageTop } from "../config/ScrollToTop";
import ButtonWithIcons from "./common/button/ButtonWithIcons";

function TabNavigator({
  sections,
  activeSection,
  validate,
  handleSubmitButton,
  setIsLastTab,
  isLastTab,
}) {
  const [t] = useTranslation();
  const [activatedSections, setActivatedSections] = useState(
    Object.entries(sections)
      .filter((section) => section[1].activated === true)
      .map((section) => section[0])
  );
  const [currentSection, setCurrentSection] = useState(activeSection);

  function handleFieldValidation() {
    return validate(sections[currentSection].validationFields);
  }

  function navigateToNextSection() {
    handleFieldValidation().then((response) => {
      if (response) {
        !activatedSections.includes(sections[currentSection].nextSection) &&
          setActivatedSections([
            ...activatedSections,
            sections[currentSection].nextSection,
          ]);
        setCurrentSection(sections[currentSection].nextSection);
        ScrollToPageTop();
      }
    });
  }

  function navigateToSection(sectionId) {
    if (!handleSubmitButton) {
      setCurrentSection(sectionId);
      ScrollToPageTop();
    } else if (activatedSections.includes(sectionId)) {
      setActivatedSections(
        activatedSections.slice(0, activatedSections.indexOf(sectionId) + 1)
      );
      setCurrentSection(sectionId);
      ScrollToPageTop();
    }
  }

  useEffect(() => {
    setIsLastTab &&
      isLastTab &&
      setIsLastTab(!activatedSections.includes(Object.keys(sections).pop()));
  }, [activatedSections]);

  useEffect(() => {
    setCurrentSection(activeSection);
  }, [activeSection]);

  return (
    <div className="tab-navigator">
      <div className="tabs">
        {}
        {Object.entries(sections).map((section) => (
          <span
            className={
              currentSection === section[0]
                ? "active"
                : handleSubmitButton &&
                  !activatedSections.includes(section[0]) &&
                  "disabled "
            }
            id={section[0]}
            onClick={(e) => navigateToSection(e.target.id)}
            data-testid={section[1].title?.toLowerCase()}
          >
            {t(section[1].title)}
          </span>
        ))}
      </div>

      <div className="navigator-body">
        {Object.entries(sections).map((section) => (
          <div className={section[0] !== currentSection && "dont-show"}>
            {section[1].block}
          </div>
        ))}
        {handleSubmitButton && (
          <div className="button-block full-width">
            {sections[currentSection].previousSection && (
              <ButtonWithIcons
                buttonText={"BACK"}
                translate={t}
                iconFirst={true}
                iconNames={[
                  {
                    iconName: "arrow_back",
                    onClick: () => {
                      ScrollToPageTop();
                      navigateToSection(
                        sections[currentSection].previousSection
                      );
                    },
                  },
                ]}
              />
            )}
            {sections[currentSection].nextSection ? (
              <CcvButton
                type="primary"
                size="normal"
                text={t("NEXT")}
                onClick={() => navigateToNextSection()}
                testId="next_button"
              />
            ) : (
              handleSubmitButton
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default TabNavigator;
