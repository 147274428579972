import * as yup from "yup";

export function CreateReleaseYupValidation() {
  return yup
    .object({
      releaseNotes: yup.string().required("required").typeError("required"),
      paymentEngineVersions: yup.array().typeError("required"),
      mapiVersion: yup.string().typeError("required"),
      apk: yup.array().min(1).length(1, "required").max(1).required("required"),
      ownerName: yup.string().required("required").typeError("required"),
      ownerRole: yup.string().typeError("required"),
      flowDescription: yup.string().required().typeError("required"),
      requirementTests: yup.boolean().required().typeError("required"),
      log4jVersion: yup.string().typeError("required"),
      applicationName: yup.string().required().typeError("required"),
      screenshots: yup
        .array()
        .min(3, "required")
        .max(5)
        .required("required")
        .typeError("required"),
      appIcon: yup.array().min(1).max(1).required("required"),
      shortDescription: yup.string().required("required").typeError("required"),
      filesToDelete: yup.array().max(5),
      description: yup.string().required("required").typeError("required"),
    })
    .required();
}
