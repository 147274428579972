import {
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../../config/AppContext";
import mockDeveloper from "../../user-developer.json";
import mockDeveloper2 from "../../user-developer-2.json";
import mockEmployee from "../../user-employee.json";
import mockEmployee2 from "../../user-employee-2.json";
import unauthorizedUser from "../../unauthorizedUser.json";
import { useTranslation } from "react-i18next";
import { IconButton, iconButtonClasses } from "@mui/material";
import { ShowForRole } from "../../components/ShowForRole";
import ButtonWithIcons from "../../components/common/button/ButtonWithIcons";
import ProjectInfoPopup from "../../components/common/ProjectInfoPopup";

function Header() {
  const { authService, title, projectInfo, setProjectInfo } =
    useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isProjectInfoOpen, setIsProjectInfoOpen] = useState(false);
  const [t] = useTranslation();

  async function isAuthenticated() {
    // When working locally, authentication is not enabled by uncommenting line below
    if (window.location.hostname === "localhost") {
      // disable the setItem line for testing
      // const user = unauthorizedUser;
      // window.sessionStorage.setItem(
      //   "oidc.user:" + process.env.REACT_APP_SSO_URL + ":developer_console",
      //   JSON.stringify(user)
      // );
      // authService.ensureAuthentication();
    } else {
      await authService
        ?.ensureAuthentication()
        .then((response) => initHeader());
    }

    function initHeader() {
      window.localStorage.setItem(
        "i18nextLng",
        authService?.user?.profile?.locale
      );

      return window.ccv_header.init({
        accessToken: authService?.access_token,
        assetsUrl:
          process.env.REACT_APP_PORTAL_URL + "back-office/header/assets",
        includeCss: true,
        titleLeft: {
          en: "Developer Centre",
          nl: "Developer Centre",
          fr: "Developer Centre",
          de: "Developer Centre",
        },
        userId: authService?.user?.profile?.sub,
        language: authService?.user?.profile?.locale,
        portalUrl: process.env.REACT_APP_PORTAL_URL,
      });
    }
  }

  useEffect(() => {
    isAuthenticated()
      .then((r) => initHeader())
      .catch((error) => error);
  }, [authService]);

  return (
    <div className="header">
      <div className="pageTitle">
        {!["/", "/home", "/actions", "/traceability"].includes(
          location.pathname
        ) && (
          <div className="back-btn" data-testid="back_button">
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <CcvMaterialIcon iconName="arrow_back" size="large" />
            </IconButton>
          </div>
        )}
        <CcvHeading text={t(title)} size="large" testId="page_title" />
        {location?.pathname?.includes("/release/detail/") && projectInfo && (
          <span className="project-info-popup">
            <ButtonWithIcons
              buttonText={projectInfo?.projectName}
              translate={t}
              iconFirst={false}
              testid={"project_name_in_header"}
              iconClassName={"project_info-popup-icon"}
              iconNames={[
                {
                  iconName: isProjectInfoOpen ? "expand_less" : "expand_more",
                },
              ]}
            />

            {projectInfo && (
              <ProjectInfoPopup project={projectInfo} translate={t} />
            )}
          </span>
        )}
      </div>
    </div>
  );
}

export default Header;
