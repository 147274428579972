import {
  CcvButton,
  CcvText,
  CcvHeading,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useContext, useEffect, useState } from "react";
import ValidationError from "../../../components/form/ValidationError";
import FormValidation from "../../../domain/FormValidation";
import SpinnerService from "../../../services/SpinnerService";
import { yupResolver } from "@hookform/resolvers/yup";
import { ShowForRole } from "../../../components/ShowForRole";
import { useForm } from "react-hook-form";
import EmptyIconBlock from "../../../components/emptyBlocks/EmptyIconBlock";
import RowTable from "../../../components/tables/rowTable/RowTable";
import PaymentEngine from "../../../domain/PaymentEngine";
import { useNavigate } from "react-router-dom";
import ReleaseStatus from "../../../domain/ReleaseStatus";
import IntegrationType from "../../../domain/IntegrationType";
import { AppContext } from "../../../config/AppContext";
import { useTranslation } from "react-i18next";
import { ReleaseService } from "../../../services/ReleaseService";
import { Spinner } from "../../../components/Loading/Spinner";
import Textarea from "../../../components/form/Textarea";
import ButtonWithIcons from "../../../components/common/button/ButtonWithIcons";
import DownloadTypes from "../../../domain/DownloadType";
import { ReleaseUpdateYupValidation } from "../../../yupValidation/ReleaseUpdateYupValidation";

function ReleaseInfoComponent({
  release,
  releaseHistory,
  setIsUpdatingRelease,
  downloadFromSharepoint,
  setPaymentEngineVersions,
  paymentEngineVersions,
  setInitialEngineVersions,
  required,
}) {
  const { authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [editReleasenotesActive, setEditReleasenotesActive] = useState(false);
  const [editGeneralInfoActive, setEditGeneralInfoActive] = useState(false);
  const [mapiVersion, setMapiVersion] = useState();
  const [releaseNotes, setReleaseNotes] = useState("");

  const {
    handleSubmit,
    setValue,
    control,
    register,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ReleaseUpdateYupValidation()),
  });

  function setEngineVersion(paymentEngine, version, isOtherOptionSelected) {
    setPaymentEngineVersions(
      paymentEngineVersions.map((engine) => {
        if (engine.key === paymentEngine) {
          engine.value = version;
          engine.isOtherOptionSelected = isOtherOptionSelected;
        }
        return engine;
      })
    );
  }

  function setOriginalFormInfo() {
    setMapiVersion(release.requirementTest?.mapiVersion);
    setReleaseNotes(release.releaseNotes);
    setPaymentEngineVersions(
      setInitialEngineVersions(release.requirementTest.paymentEngineVersions)
    );
    setValue(
      "paymentEngineVersions",
      release.requirementTest?.paymentEngineVersions
    );
    setValue("mapiVersion", release.requirementTest?.mapiVersion);
    setValue("releaseNotes", release.releaseNotes);
  }

  useEffect(() => {
    paymentEngineVersions &&
      setValue("paymentEngineVersions", paymentEngineVersions);
    setValue("mapiVersion", mapiVersion);
  }, [paymentEngineVersions, mapiVersion]);

  useEffect(() => {
    if (release) {
      setOriginalFormInfo();
    }
  }, [release]);

  function updateReleaseNotes(data) {
    setIsUpdatingRelease(true);
    if (ReleaseStatus[release.releaseStatus].step !== "AVAILABLE_IN_STORE") {
      SpinnerService.defaultSpinner(
        releaseService
          .patchReleaseNotes(release.id, data.releaseNotes)
          .then((response) => {
            setEditReleasenotesActive(false);
          }),
        t,
        "TOAST_PROCESSING",
        "TOAST_RELEASENOTES_UPDATED",
        setIsUpdatingRelease
      );
    }
  }

  function isEngineVersionChanged(data) {
    return (
      data.paymentEngineVersions.filter(
        (engine, id) =>
          engine.value !=
          release.requirementTest.paymentEngineVersions[id].version
      ).length > 0
    );
  }

  function getReleaseCompletedDate() {
    return (
      releaseHistory &&
      releaseHistory
        .filter(
          (historyRow) =>
            historyRow.status === ReleaseStatus.AVAILABLE_IN_STORE.key
        )
        .slice(-1)
    );
  }

  function updateReleaseInfo(data) {
    setIsUpdatingRelease(true);
    if (
      isEngineVersionChanged(data) ||
      data.mapiVersion != release.requirementTest.mapiVersion
    ) {
      SpinnerService.defaultSpinner(
        releaseService
          .patchReleaseInfo(release.id, {
            paymentEngineVersions: isEngineVersionChanged(data)
              ? data.paymentEngineVersions.map((engine) => {
                  return { name: engine.key, version: engine.value };
                })
              : [],
            mapiVersion: data.mapiVersion,
            lastEditedBy: authService.getEmail(),
            lastUpdated: Date.now(),
          })
          .then((response) => {
            setEditGeneralInfoActive(false);
          }),
        t,
        "TOAST_PROCESSING",
        "TOAST_GENERALINFO_UPDATED",
        setIsUpdatingRelease
      );
    }
  }

  function getGeneralInfoTimeLine() {
    if (release) {
      return (
        <Spinner area="history-area" dataIsFetched={releaseHistory}>
          <RowTable
            translate={t}
            data={[
              {
                columnName: "RELEASE_VERSION_LABEL",
                data: release.version,
              },
              {
                columnName: "RELEASE_CREATED_AT_LABEL",
                data: release.creationDate,
                type: "date",
              },
              {
                columnName: "RELEASE_LAST_UPDATED_LABEL",
                data: releaseHistory && releaseHistory[0]?.creationDate,
                type: "date",
              },
              {
                columnName: "RELEASE_RELEASEDATE_LABEL",
                data:
                  getReleaseCompletedDate()?.length > 0
                    ? getReleaseCompletedDate()[0].creationDate
                    : "NOT_RELEASED_YET",
                type:
                  getReleaseCompletedDate()?.length > 0
                    ? "date"
                    : "unavailable",
              },
            ]}
          />
        </Spinner>
      );
    } else {
      return (
        <EmptyIconBlock emptyText="PROJECT_NO_HISTORY_LABEL" translate={t} />
      );
    }
  }

  function getGeneralInfoRelease() {
    if (release) {
      return (
        <form>
          <RowTable
            translate={t}
            data={[
              {
                columnName: "PAYMENT_ENGINE_LABEL",
                options: PaymentEngine,
                data: release?.project?.paymentEngineTypes,
                type: "list",
              },
              {
                columnName: "PAYMENT_ENGINE_VERSION_LABEL",
                type: "editable_select_list",
                name: "paymentEngineVersions",
                data: paymentEngineVersions,
                options: PaymentEngine,
                setValue: setEngineVersion,
                initialValue: release.paymentEngineVersions,
                maxLength: FormValidation.MAX_ENGINE_VERSION_LENGTH,
              },
              mapiVersion !== "NOT_USED" && {
                columnName: "MAPI_VERSION_DETAIL_LABEL",
                data: mapiVersion,
                type: "editable_select",
                name: "mapiVersion",
                maxLength: FormValidation.MAX_MAPI_LENGTH,
                options: IntegrationType.MAPI.versions,
                initialValue: release.requirementTest.mapiVersion,
                setValue: setMapiVersion,
              },
              {
                columnName: "RELEASE_APK_LABEL",
                data: "DOWNLOAD_UNSIGNED_LABEL",
                function: () =>
                  downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                type: "download",
              },
              {
                columnName: "RESPONSIBLE_APK_LABEL",
                data:
                  release.releaseOwner?.name +
                  (release.releaseOwner?.role &&
                    " (" + release.releaseOwner?.role + ")"),
              },
              {
                columnName: "REQUIREMENT_TITLE",
                data: "TEST_CATALOGUE_BUTTON",
                function: () =>
                  navigate("/release/detail/testcatalogue", {
                    state: {
                      requirementTest: release.requirementTest,
                    },
                  }),
                type: "link",
              },
            ]}
            editActive={editGeneralInfoActive}
            formControl={control}
            register={register}
          />
        </form>
      );
    } else {
      return (
        <EmptyIconBlock emptyText="RELEASE_INFO_TEXT_NO_INFO" translate={t} />
      );
    }
  }

  function getReleaseNotes() {
    return (
      release && (
        <form>
          <div className="release-notes-block ">
            <CcvText size="medium" color="dark-grey" textStyle="bold">
              {t("RELEASE_NOTES_LABEL") +
                (required && editReleasenotesActive ? " *" : "")}
            </CcvText>{" "}
            {!editReleasenotesActive && (
              <ShowForRole permission="Developer">
                {![
                  ReleaseStatus.AVAILABLE_IN_STORE.key,
                  ReleaseStatus.SECURITYSCAN_FAILED.key,
                  ReleaseStatus.FUNCTIONAL_TESTING_FAILED.key,
                  ReleaseStatus.SIGNING_FAILED.key,
                ].includes(release.releaseStatus) && (
                  <ButtonWithIcons
                    iconFirst={false}
                    buttonText={"RELEASE_BUTTON_EDIT_RELEASENOTES"}
                    iconNames={[
                      {
                        iconName: "edit",
                        onClick: () =>
                          setEditReleasenotesActive(!editReleasenotesActive),
                      },
                    ]}
                    translate={t}
                  />
                )}
              </ShowForRole>
            )}
          </div>
          <Textarea
            translate={t}
            field={"releaseNotes"}
            defaultInputData={releaseNotes}
            disabled={!editReleasenotesActive}
            testId={"releasenotes"}
            className="textarea-medium"
            maxLength={FormValidation.MAX_RELEASENOTES_LENGTH}
            setInput={setReleaseNotes}
            shouldValidateOnInput={false}
            clearErrors={clearErrors}
            setValue={setValue}
            control={control}
            validationError={
              <ValidationError
                error={errors["releaseNotes"]}
                errorText={t("RELEASE_INFO_TEXT_ADD_RELEASE_NOTES")}
                testId="release_notes_error"
              />
            }
          />
          {editReleasenotesActive && (
            <div className="release-notes-button-block">
              <div className="release-notes-edit">
                <CcvButton
                  disabled={!release}
                  type="tertiary"
                  size="small"
                  text={t("CANCEL_LABEL")}
                  onClick={() => {
                    setEditReleasenotesActive(false);
                    setReleaseNotes(release.releaseNotes);
                    clearErrors("releaseNotes");
                    setValue("releaseNotes", release.releaseNotes);
                  }}
                  testId="cancel_release_info_button"
                />
                <CcvButton
                  size="small"
                  type="primary"
                  text={t("SAVE_LABEL")}
                  onClick={handleSubmit(updateReleaseNotes)}
                  testId="save_release_info_button"
                />
              </div>
            </div>
          )}
        </form>
      )
    );
  }

  return (
    <div className="info-block">
      <div className="header-with-edit">
        <CcvHeading
          color="blue"
          text={t("RELEASE_GENERAL_LABEL")}
          size="small"
          testId="release_general_info_title"
        />
        <ShowForRole permission="Employee">
          {editGeneralInfoActive ? (
            <div>
              <CcvButton
                text={t("CANCEL_LABEL")}
                size="small"
                type="tertiary"
                onClick={() => {
                  setEditGeneralInfoActive(false);
                  setOriginalFormInfo();
                }}
                testId="cancel_edit_button"
              />
              <CcvButton
                text={t("SAVE_LABEL")}
                size="small"
                type="primary"
                onClick={handleSubmit(updateReleaseInfo)}
                testId="save_release_info_button"
              />
            </div>
          ) : (
            <CcvButton
              text={t("EDIT_LABEL")}
              size="small"
              type="secondary"
              onClick={() => setEditGeneralInfoActive(true)}
              testId="edit_release_info_button"
              disabled={!release}
            />
          )}
        </ShowForRole>
      </div>

      <div className="multi-side-block gap">
        <div className="left">
          {[getGeneralInfoTimeLine(), getReleaseNotes()]}
        </div>
        <div className="right">{getGeneralInfoRelease()}</div>
      </div>
    </div>
  );
}

export default ReleaseInfoComponent;
