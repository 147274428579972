import { CcvMaterialIcon } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import Slider from "react-slick";
import "../../style/Slider.scss";

function ImageCarousel({ images, testId, altText }) {
  const settings = {
    accessibility: true,
    adaptiveHeight: false,
    centerMode: false,
    focusOnSelect: false,
    vertical: false,
    fade: false,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 1000,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    pauseOnHover: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <span onClick={onClick} className={className + " slick-nav-button"}>
        <CcvMaterialIcon iconName="keyboard_arrow_right" size="large" />
      </span>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <span onClick={onClick} className={className + " slick-nav-button"}>
        <CcvMaterialIcon iconName="keyboard_arrow_left" size="large" />
      </span>
    );
  }

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {images?.map((image) => {
          return (
            <div className="slick-element">
              <img
                data-testid={testId}
                src={image.preview}
                alt={altText}
                className="accepted-screenshots"
                id={image.name}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default ImageCarousel;
