import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import Textarea from "../../../components/form/Textarea";
import FormValidation from "../../../domain/FormValidation";
import ValidationError from "../../../components/form/ValidationError";

function FunctionalTestingFormComponent({
  flowDescription,
  errors,
  clearError,
  setValue,
}) {
  const [t] = useTranslation();

  return (
    <div className="multi-side-block gap functional-testing-info">
      <div className="left">
        <CcvText size="medium" color="dark-grey">
          {t("FUNCTIONAL_TESTING_TESTS_LABEL")}
          <a href="https://developer.myccv.eu/documentation/certification/">
            {" " + t("HERE_TEXT")}
          </a>
          .
        </CcvText>
        <CcvText size="medium" color="dark-grey">
          {t("FUNCTIONAL_TESTING_EXECUTE_TESTS_LABEL")}
        </CcvText>
        <CcvText size="medium" color="dark-grey" textStyle="bold">
          {t("FUNCTIONAL_TESTING_LABEL")}
        </CcvText>
        <CcvText size="medium" color="dark-grey">
          {t("FUNCTIONAL_TESTING_FLOW_LABEL")}
        </CcvText>
        <CcvText size="medium" color="dark-grey">
          {t("FUNCTIONAL_TESTING_FLOW_CREDENTIALS_LABEL")}
        </CcvText>
      </div>
      <div className="right">
        <Textarea
          shouldValidateOnInput={false}
          clearErrors={clearError}
          translate={t}
          labelText={t("FUNCTIONAL_TESTING_HEADING")}
          maxLength={FormValidation.MAX_FLOW_DESCRIPTION_LENGTH}
          className={"textarea-extra-large"}
          placeholder={t("FLOW_PLACEHOLDER")}
          field={"flowDescription"}
          defaultInputData={flowDescription && flowDescription}
          required={true}
          setValue={setValue}
          validationError={
            <ValidationError
              error={errors["flowDescription"]}
              errorText={t("FLOW_DESCRIPTION_REQUIRED")}
              testId="flow_description_error"
            />
          }
          testId={"flow_description"}
        />
      </div>
    </div>
  );
}

export default FunctionalTestingFormComponent;
