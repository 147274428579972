import React from "react";
import Textarea from "../../../components/form/Textarea";
import TextInput from "../../../components/form/TextInput";
import ValidationError from "../../../components/form/ValidationError";
import FormValidation from "../../../domain/FormValidation";
import Dropzone from "../../../components/form/Dropzone";
import { useTranslation } from "react-i18next";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import TooltipText from "../../../domain/TooltipText";
import ButtonWithIcons from "../../../components/common/button/ButtonWithIcons";
import DownloadTypes from "../../../domain/DownloadType";
import InfoTextWithIcon from "../../../components/common/InfoTextWithIcon";

function CcvStoreInfo({
  clearError,
  control,
  errors,
  setValue,
  defaultData,
  setFilesToDelete,
  disabled,
  multiColumnView,
  downloadFromSharepoint,
}) {
  const [t] = useTranslation();

  return (
    <div>
      <>
        <InfoTextWithIcon
          testId={"application_info_tooltip"}
          translatedInfoText={t(TooltipText.APPLICATION_INFORMATION)}
        />

        <div
          className={
            multiColumnView
              ? "multi-side-block gap"
              : "one-side-block" + " gap form-spacing"
          }
        >
          <div className="left">
            <TextInput
              field="applicationName"
              clearErrors={clearError}
              disabled={disabled}
              shouldValidateOnInput={false}
              setValue={setValue}
              maxLength={FormValidation.MAX_APPLICATION_NAME_LENGTH}
              label={t("APPLICATION_NAME_LABEL")}
              defaultInputData={defaultData?.applicationName}
              required={true}
              validationError={
                <ValidationError
                  errorText={t("APPLICATION_NAME_REQUIRED")}
                  error={errors["applicationName"]}
                  testId="application_name_error"
                />
              }
            />
            <div className="form-section small-dropzone">
              <Dropzone
                tooltipInfo={["APPICON_TOOLTIP_TEXT"]}
                clearError={clearError}
                shouldValidateOnInput={false}
                label={"APP_ICON_LABEL"}
                disabled={disabled}
                defaultValue={defaultData?.appIcon}
                infoMessage={"APP_ICON_INFO_MESSAGE"}
                setValue={setValue}
                required={true}
                errors={errors}
                errorText="APP_ICON_REQUIRED"
                rejectedErrorText="APPICON_REJECTED"
                acceptedExtensions={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpeg", ".jpg"],
                }}
                registerName="appIcon"
                maxSize={FormValidation.MAX_ICON_SIZE}
              />
              {disabled && (
                <ButtonWithIcons
                  iconFirst={true}
                  iconNames={[
                    {
                      iconName: "file_download",
                      onClick: () =>
                        downloadFromSharepoint(
                          DownloadTypes.APP_ICON,
                          defaultData?.appIcon
                        ),
                    },
                  ]}
                  iconClassName="icon-blue"
                  buttonText={defaultData?.appIcon[0].name}
                  textClassName="download-link"
                  translate={t}
                  required={true}
                />
              )}
            </div>
            <div className="form-section small-dropzone">
              <Dropzone
                minFiles={3}
                label={"SCREENSHOTS_LABEL"}
                disabled={disabled}
                tooltipInfo={["SCREENSHOT_TOOLTIP_TEXT"]}
                infoMessage={"SCREENSHOTS_INFO"}
                setValue={setValue}
                clearError={clearError}
                shouldValidateOnInput={false}
                errors={errors}
                errorText="SCREENSHOTS_REQUIRED"
                rejectedErrorText="SCREENSHOTS_REJECTED"
                required={true}
                acceptedExtensions={{
                  "image/png": [".png"],
                  "image/jpeg": [".jpeg", ".jpg"],
                }}
                registerName="screenshots"
                maxSize={FormValidation.MAX_SCREENSHOTS_SIZE}
                maxAmount={FormValidation.MAX_SCREENSHOTS_SIZE}
                maxFiles={FormValidation.MAX_SCREENSHOTS_LENGTH}
                defaultValue={defaultData?.screenshots}
                setDeletionFiles={setFilesToDelete}
              />
              {disabled && (
                <ButtonWithIcons
                  iconFirst={true}
                  iconNames={[
                    {
                      iconName: "file_download",
                      onClick: () =>
                        downloadFromSharepoint(
                          DownloadTypes.SCREENSHOT,
                          defaultData.screenshots
                        ),
                    },
                  ]}
                  iconClassName="icon-blue"
                  buttonText={"SCREENSHOTS_LABEL"}
                  textClassName="download-link"
                  translate={t}
                />
              )}
            </div>
          </div>
          <div className="right">
            <div className="form-section">
              <Textarea
                className={"textarea-small"}
                clearErrors={clearError}
                setValue={setValue}
                shouldValidateOnInput={false}
                disabled={disabled}
                translate={t}
                labelText={"SHORT_DESCRIPTION_LABEL"}
                defaultInputData={defaultData?.shortDescription}
                maxLength={FormValidation.MAX_SHORT_DESCRIPTION_LENGTH}
                control={control}
                field={"shortDescription"}
                required={true}
                validationError={
                  <ValidationError
                    error={errors["shortDescription"]}
                    errorText={t("SHORT_DESCRIPTION_REQUIRED")}
                    testId="short_description_error"
                  />
                }
              />
            </div>
            <div className="form-section">
              <Textarea
                className={"textarea-large"}
                disabled={disabled}
                clearErrors={clearError}
                setValue={setValue}
                shouldValidateOnInput={false}
                translate={t}
                labelText={"DESCRIPTION_LABEL"}
                defaultInputData={defaultData?.description}
                maxLength={FormValidation.MAX_DESCRIPTION_LENGTH}
                control={control}
                field={"description"}
                required={true}
                validationError={
                  <ValidationError
                    error={errors["description"]}
                    errorText={t("DESCRIPTION_REQUIRED")}
                    testId="description_error"
                  />
                }
              />
            </div>
          </div>
        </div>
      </>
    </div>
  );
}

export default CcvStoreInfo;
