import {
  CcvButton,
  CcvHeading,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RowTable from "../../../components/tables/rowTable/RowTable";
import RadioButtonBody from "../../../components/dialogs/DialogBodies/updateBodies/RadioButtonBody";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ResultLabel from "../../../components/common/ResultLabel";
import { AppContext } from "../../../config/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadLink from "../../../components/common/button/DownloadLink";
import SpinnerService from "../../../services/SpinnerService";
import { ReleaseService } from "../../../services/ReleaseService";
import DownloadTypes from "../../../domain/DownloadType";
import FormatService from "../../../services/FormatService";
import ContractCheckbox from "../../../components/form/ContractCheckbox";
import RichTextEditor from "../../../components/form/RichTextEditor";
import Dropzone from "../../../components/form/Dropzone";
import FormValidation from "../../../domain/FormValidation";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { SecurityscanYupValidation } from "../../../yupValidation/SecurityscanYupValidation";
import TextInput from "../../../components/form/TextInput";
import { Spinner } from "../../../components/Loading/Spinner";

function SecurityScanComponent(props) {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setTitle, setProjectInfo, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [status, setStatus] = useState();
  const [release, setRelease] = useState();
  const { promiseInProgress } = usePromiseTracker({
    area: "updatingSecurityscan",
    delay: 0,
  });
  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    clearErrors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(
      SecurityscanYupValidation(release && release.securityscan)
    ),
  });

  useEffect(() => {
    setTitle(t(props.title));
    trackPromise(
      SpinnerService.errorSpinner(fetchReleaseData(), t),
      "release-area"
    );
  }, []);

  useEffect(() => {
    status === "SECURITYSCAN_FAILED" && setIsContractSigned(false);
    setValue("contract", isContractSigned, { shouldValidate: true });
  }, [status]);

  function fetchReleaseData() {
    return releaseService
      .getReleaseByIdAndBupaId(
        location.state.release?.id,
        authService.getBupaId()
      )
      .then((response) => {
        setRelease(response.data);
        setProjectInfo(response.data.project);
      })
      .catch((err) => {
        throw err;
      });
  }

  function getScannerResultList() {
    return release?.securityscan?.jotti?.scannerResults?.map(
      (scannerResult) => {
        return {
          name: scannerResult.name,
          description: scannerResult.unknownResultReason
            ? t("SCAN_RESULT_UNKNOWN_LABEL")
            : scannerResult.packageName,
          status: scannerResult.unknownResultReason ? "UNKNOWN" : "FAILED",
        };
      }
    );
  }

  function downloadFromSharepoint(fileType) {
    SpinnerService.defaultSpinner(
      releaseService.getDownloadLink(release.id, fileType).then((response) => {
        return FormatService.downloadFileWithNewFilename(
          response.data.downloadUrl,
          fileType != DownloadTypes.SECURITYSCAN_REPORT
            ? FormatService.getUnsignedFileName(
                response.data.fileName,
                release.project?.apkInfo?.packageName,
                release.version,
                release.project?.projectName
              )
            : response.data.fileName
        );
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function onSubmit(data) {
    trackPromise(
      SpinnerService.defaultSpinner(
        updateSecurityscan(data),
        t,
        "TOAST_PROCESSING",
        "TOAST_RELEASE_UPDATED"
      ),
      "updatingSecurityscan"
    );
  }

  function updateSecurityscan(data) {
    return releaseService
      .patchReleaseSecurityScanStatus(
        release.id,
        getSecurityUpdateFormData(data)
      )
      .then((response) =>
        navigate("/release/detail/" + release.id, { replace: true })
      );
  }

  function getSecurityUpdateFormData(data) {
    const formData = new FormData();
    data.lastUpdated = Date.now();
    data.lastEditedBy = authService.getEmail();
    data.scanJobId =
      data.jottiUrl && FormatService.extractIdFromUrl(data.jottiUrl);
    data.mobsfHash =
      data.mobsfUrl && FormatService.extractIdFromUrl(data.mobsfUrl);
    data.report?.length > 0 &&
      formData.append(
        "file",
        new File(
          [data.report[0]],
          FormatService.replaceSpecialChar(data.report[0].name),
          {
            type: data.report[0].type,
          }
        )
      );
    formData.append(
      "securityScanRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    return formData;
  }

  function getJottiBody(progress) {
    switch (progress) {
      case "RUNNING":
        return getResultLabel(
          "SCAN_ONGOING_LABEL",
          "ongoing",
          "jotti_scan_result_label_"
        );
      case "FINISHED":
        return getFinishedJottiBody(
          release?.securityscan?.jotti?.scannerResults
        );
      case "JOTTI_DISABLED":
        return getResultLabel(
          "JOTTI_DISABLED_LABEL",
          "warning",
          "jotti_scan_result_label_"
        );
      default:
        return (
          <TextInput
            maxLength={FormValidation.MAX_URL_INPUT}
            isMaxCharHidden={true}
            field="jottiUrl"
            setValue={setValue}
            shouldValidateOnInput={true}
            placeholder={"JOTTI_URL_PLACEHOLDER"}
            clearErrors={clearErrors}
          />
        );
    }
  }

  function getMobsfBody(progress) {
    if (progress === "RUNNING") {
      return getResultLabel(
        "SCAN_ONGOING_LABEL",
        "ongoing",
        "mobsf_scan_result_label_"
      );
    } else if (progress === "FINISHED") {
      return release?.securityscan?.reportFileAvailable ? (
        <DownloadLink
          testId="download_mobsf_report"
          translatedText={t("DOWNLOAD_RAPPORT_LABEL")}
          downloadFunction={() =>
            downloadFromSharepoint(DownloadTypes.SECURITYSCAN_REPORT)
          }
        />
      ) : (
        <CcvText size="normal" color="grey">
          {t("REPORT_NOT_AVAILABLE_TEXT")}
        </CcvText>
      );
    }
    return (
      <div className="rowtable_input_form">
        <TextInput
          field="mobsfUrl"
          setValue={setValue}
          shouldValidateOnInput={true}
          placeholder={"MOBSF_URL_PLACEHOLDER"}
          clearErrors={clearErrors}
          maxLength={FormValidation.MAX_URL_INPUT}
          isMaxCharHidden={true}
        />
        <Dropzone
          setValue={setValue}
          acceptedExtensions={{ "file/type": [".pdf", ".docx"] }}
          registerName="report"
          maxSize={FormValidation.MAX_REPORT_FILE_SIZE}
          isLabelBold={true}
          shouldValidateOnInput={true}
          required={true}
        />
      </div>
    );
  }

  function getFinishedJottiBody(scannerResults) {
    if (
      scannerResults &&
      release?.securityscan?.jotti?.scannerResults?.filter(
        (ScannerResult) => ScannerResult.packageName
      ).length == 0
    ) {
      return getResultLabel("OK", "success", "jotti_scan_result_label_");
    }
    return getResultLabel("FAILED_TEXT", "failed", "jotti_scan_result_label_");
  }

  function getResultLabel(text, status, testId) {
    return (
      <ResultLabel
        status={status}
        translatedText={t(text).toLocaleUpperCase()}
        testId={testId + status}
      />
    );
  }

  function isApiCallFailed(progress) {
    return !["FINISHED", "RUNNING", "JOTTI_DISABLED"].includes(progress);
  }

  function getColumnTitleSubText() {
    if (!release?.securityscan) {
      return "RELEASE_INFO_TEXT_NO_INFO";
    }
    if (isApiCallFailed(release?.securityscan?.jotti?.progress)) {
      return "API_CALL_FAILED_EMPLOYEE_TEXT";
    }
    return "";
  }

  return (
    <div className="info multi-side-block gap">
      <div className="info-block left ">
        <div className="header-with-edit spacing-bottom">
          <CcvHeading
            color="blue"
            text={t("INPUT_TEXT")}
            size="small"
            testId="release_general_info_title"
          />
        </div>
        <Spinner
          area="release-area"
          dataIsFetched={release}
          accessDenied={false}
        >
          <RowTable
            translate={t}
            data={[
              (isApiCallFailed(release?.securityscan?.jotti?.progress) ||
                isApiCallFailed(release?.securityscan?.mobsf?.progress)) && {
                columnName: "RELEASE_APK_LABEL",
                data: "BASICTABLE_DOWNLOADLINK",
                function: () =>
                  downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                type: "download",
              },
              {
                columnName: "JOTTI_SCAN_RESULT_LABEL",
                columnRequired: isApiCallFailed(
                  release?.securityscan?.jotti?.progress
                ),
                columnSubText: getColumnTitleSubText(),
                columnSubTextColor: !release?.securityscan
                  ? "medium-grey"
                  : "red",
                data: release,
                url:
                  release?.securityscan?.jotti?.progress === "FINISHED" &&
                  release?.securityscan?.jotti?.scanJobIdUrl,
                testId: "jotti_scan_result",
                list: getScannerResultList(),
                element: getJottiBody(release?.securityscan?.jotti?.progress),
                type: "scanner_result_with_list",
              },
              {
                columnName: "MOBSF_RAPPORT_LABEL",
                data: release,
                columnSubText: getColumnTitleSubText(),
                columnSubTextColor: !release?.securityscan
                  ? "medium-grey"
                  : "red",
                url:
                  release?.securityscan?.mobsf?.progress === "FINISHED" &&
                  release?.securityscan?.mobsf?.hashUrl,
                testId: "mobsf_scan_result",
                element: getMobsfBody(release?.securityscan?.mobsf?.progress),
                type: "scanner_result_with_list",
              },
            ]}
          />
        </Spinner>
      </div>
      <div className="info-block right">
        <div className="header-with-edit">
          <CcvHeading
            color="blue"
            text={t("RESULT_LABEL")}
            size="small"
            testId="release_general_info_title"
          />
        </div>
        <form>
          <div className="input-block-checkbox">
            <RadioButtonBody
              control={control}
              translate={t}
              setValue={setStatus}
              title={"PROGRESSBAR_SCAN"}
              required={true}
              options={[
                {
                  id: "success",
                  value: "SECURITYSCAN_SUCCESS",
                  text: "SUCCESS_TEXT",
                  icon: "done",
                  color: "green",
                  testid: "securityscan_success_option",
                },
                {
                  id: "failed",
                  value: "SECURITYSCAN_FAILED",
                  text: "FAILED_TEXT",
                  icon: "clear",
                  color: "red",
                  testid: "securityscan_failed_option",
                },
              ]}
            />
          </div>

          <CcvText size="medium" color="dark-grey" textStyle="semi-bold">
            {t("DIALOG_FEEDBACK")}
          </CcvText>
          <RichTextEditor
            maxLength={FormValidation.MAX_FEEDBACK_LENGTH_LARGE}
            setValue={setValue}
            registerName={"feedback"}
            validate={trigger}
          />
          {status === "SECURITYSCAN_SUCCESS" && (
            <ContractCheckbox
              translate={t}
              testId={"securityscan_authorize"}
              setChecked={setIsContractSigned}
              isChecked={isContractSigned}
              text={"SUCRITYSCAN_SUCCESS_AUTHORIZE_LABEL"}
              registerName={"contract"}
              setValue={setValue}
            />
          )}

          <div className="button-block full-width">
            <CcvButton
              type="tertiary"
              size="normal"
              text={t("CANCEL_LABEL")}
              onClick={() => navigate(-1)}
              disabled={promiseInProgress}
              testId="cancel_status_update_button"
            />
            <CcvButton
              type="primary"
              size="normal"
              text={t("SAVE_LABEL")}
              onClick={handleSubmit(onSubmit)}
              disabled={promiseInProgress || !isValid}
              testId="update_status_button"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default SecurityScanComponent;
